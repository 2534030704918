<template>
  <section class="description-documents table-items mt-5">
    <b-row class="table-items-header">
      <b-col xs="12" sm="12" md="3">
        <h6 class="h6 font-bold">Descripción</h6>
      </b-col>
      <b-col xs="12" sm="12" md="2">
        <h6 class="h6 font-bold ml-2">Porcentaje</h6>
      </b-col>
      <b-col xs="12" sm="12" md="2">
        <h6 class="h6 font-bold">Base imponible</h6>
      </b-col>
      <b-col xs="12" sm="12" md="1">
        <h6 class="h6 font-bold">Total</h6>
      </b-col>
      <b-col xs="12" sm="12" md="4">
        <h6 class="h6 font-bold">Documento</h6>
      </b-col>
    </b-row>
    <b-row
      class="mt-2 d-flex align-items-center"
      v-for="(tax, indexTax) in taxes"
      :key="indexTax"
    >
      <b-col xs="12" sm="12" md="3">
        <p class="m-0 ml-2">
          <strong>Anexo: {{ tax.annex }}</strong>
        </p>
        <p class="ml-2">{{ tax.title }}</p>
      </b-col>
      <b-col xs="12" sm="12" md="2">
        <p class="">{{ `${tax.percentage}%` }}</p>
      </b-col>
      <b-col xs="12" sm="12" md="2">
        <el-input-number
          :controls="false"
          placeholder="0.00"
          v-model="tax.amount"
          :precision="2"
          :step="0.1"
        ></el-input-number>
      </b-col>
      <b-col xs="12" sm="12" md="1">
        ${{ calculatePercentage(tax.amount, tax.percentage) }}
      </b-col>
      <b-col xs="12" sm="12" md="4">
        <ButtonIcoComponent
          class="float-right"
          @onClick="removeTaxes(indexTax)"
        />
        <el-select v-model="tax.sri_document_type_code">
          <el-option
            :value="document.code"
            :label="document.title"
            :key="index"
            v-for="(document, index) in sriDocumentTypes"
          ></el-option>
        </el-select>
        <div class="d-flex mt-2">
          <el-input
            type="text"
            placeholder="000-000-000000000"
            class="mr-2"
            v-model="tax.document_identification"
          ></el-input>
          <InputDatePickerComponent
            @onChangeDatePicker="date => onChangeDatePicker(date, indexTax)"
            placeholder="Fecha emisión"
            title=""
            :isDisabledDate="true"
            :setDate="tax.emission_date"
          />
        </div>
      </b-col>
    </b-row>

    <el-autocomplete
      v-model="tax"
      popper-class="my-autocomplete"
      v-if="isSearchTaxes"
      :fetch-suggestions="querySearchAsync"
      :trigger-on-focus="false"
      placeholder="Buscar impuesto de retención"
      @select="onSelectTax"
      suffix-icon="el-icon-search"
      class="mt-4 mb-2"
    >
      <template slot-scope="{ item }">
        <div class="value">{{ item.title }}</div>
      </template>
    </el-autocomplete>

    <hr style="margin-top: 12rem;" />
  </section>
</template>

<script>
import ButtonIcoComponent from "../../../../components/ButtonIco";
import InputDatePickerComponent from "../../../../components/InputDatePicker";
import { parseFloatFormat } from "@/assets/utils/format";
import SriServices from "@/services/SriServices";

export default {
  name: "WithholdingTableTaxes",
  data() {
    return {
      taxes: this.setTaxes || [],
      sriWithholdingTaxes: [],
      sriDocumentTypes: [],
      tax: null
    };
  },
  watch: {
    setTaxes(taxes) {
      this.taxes = taxes;
    }
  },
  methods: {
    calculatePercentage(value, percentage) {
      return parseFloatFormat((value / 100) * percentage);
    },
    removeTaxes(index) {
      this.taxes.splice(index, 1);
      this.onToogleTaxes();
    },
    onToogleTaxes() {
      this.$emit("onToogleTaxes", { taxes: this.taxes });
    },
    querySearchAsync(queryString, cb) {
      const sriWithholdingTaxes = this.sriWithholdingTaxes;
      const results = queryString
        ? sriWithholdingTaxes.filter(this.createFilter(queryString))
        : sriWithholdingTaxes;
      cb(results);
    },
    createFilter(queryString) {
      return tax => {
        let search = queryString.toLowerCase();
        return (
          tax.title.toLowerCase().indexOf(search) === 0 ||
          tax.annex.toLowerCase().indexOf(search) === 0
        );
      };
    },
    onSelectTax(tax) {
      const { annex, percentage, title, id: sri_withholding_tax_id } = tax;
      this.taxes.push({
        sri_withholding_tax_id,
        annex,
        percentage,
        title,
        amount: 0,
        sri_document_type_code: null,
        document_identification: null,
        emission_date: null
      });
      this.onToogleTaxes();
    },
    onChangeDatePicker({ date }, indexTax) {
      this.taxes[indexTax].emission_date = date;
    },
    async getWithholdingTaxes() {
      const response = await SriServices.getWithholdingTaxes();
      const { data, success } = response.data;
      if (success) this.sriWithholdingTaxes = data;
    },
    async getDocumentTypes() {
      const response = await SriServices.getDocumentTypes();
      const { data, success } = response.data;
      if (success) this.sriDocumentTypes = data;
    }
  },
  components: {
    ButtonIcoComponent,
    InputDatePickerComponent
  },
  props: {
    setTaxes: {
      type: Array
    },
    isSearchTaxes: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.getWithholdingTaxes();
    this.getDocumentTypes();
  }
};
</script>

<style lang="scss" scoped>
.el-autocomplete {
  width: 100% !important;
}
</style>
